<template>
  <div class="collect">
    <van-list 
		  v-model="isLoading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad">
      <div class="collect-head">
        <van-checkbox-group v-model="result" ref="checkboxGroup">
          <div class="collect-content" v-for="(item, index) in collectList" :key="index">
            <van-checkbox 
						  v-if="editStaus===true"
              :name="item.app_id"
              icon-size=".32rem"
              class="collect-content-check" 
							/>
            <div class="collect-content-img">
              <img :src="item.cover_url" width="100%" height="100%"/>
            </div>
            <div class="collect-content-text" @click="goDetails(item.app_id)">
              <div class="collect-content-text-name">{{item.case_name}}</div>
              <div class="collect-content-text-detail">{{item.introduction}}</div>
              <div class="collect-content-text-range">
                <span class="text-tag" v-if="item.industry_typ !== ''">{{item.industry_type}}</span>
                <span class="text-tag marginL" v-if="item.h5 == 1">H5</span>
                <span class="text-tag marginL" v-if="item.micro_program == 1">小程序</span>
              </div>
              <div class="collect-content-text-address" v-if="item.city !== ''">
                <img :src="require('../../assets/address1.png')"
                     class="address-img" />
                <span class="address-province">{{ item.city }}</span>
              </div>
            </div>
          </div>
        </van-checkbox-group>
      </div>
    </van-list>
    <div class="collect-footer">
      <div class="collect-footer-content" v-if="editStaus===true">
        <van-checkbox @click="checkAll" v-model="allChecked" icon-size=".32rem">全选</van-checkbox>
        <span>
          <van-button round class="collect-footer-btn" @click="handleCancel">取消收藏</van-button>
          <van-button round type="info" class="collect-footer-btn marginL" @click="handleFinish">完成</van-button>
        </span>
      </div>
      <div class="collect-footer-edit" v-else>
        <van-button round type="info" class="collect-footer-btn" @click="handleEdit">编辑</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog, PullRefresh, Toast } from 'vant';
import { setTimeout } from 'timers';

export default {
  data() {
    return {
      collectList: [],
      result: [],
      isAllCheck: [],
      allChecked: false, // 是否全选
      editStaus: false, // 是否编辑
      isLoading: false,
      finished: false, // 是否加载完成
      page_index: 1,
      page_size: 10,
      total: 0,
    };
  },
  watch: {
    result(val) {
      if (val.length < this.isAllCheck.length) this.allChecked = false
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http
        .get('/user_service/case_library/get_collection_list', {
          page_index: this.page_index,
          page_size: this.page_size,
        })
        .then((res) => {
          const { code, data } = res;
          if (code === 0) {
            console.log(data, 'data');
            this.collectList = data.data;
            this.total = data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //   全选操作
    checkAll() {
      this.allChecked = !this.allChecked;
      if (this.allChecked) {
        this.allChecked = false;
        this.$refs.checkboxGroup.toggleAll(false);
      } else {
        this.allChecked = true;
        this.$refs.checkboxGroup.toggleAll(true);
        this.isAllCheck = this.result
      }
    },
    // 编辑
    handleEdit() {
      this.editStaus = true;
    },
    // 完成按钮
    handleFinish() {
      this.editStaus = false;
    },
    // 取消
    handleCancel() {
      if (this.result.length) {
        Dialog.confirm({
        message: '确定取消所选的收藏数据？',
      }).then(() => {
        this.$http.delete("/user_service/case_library/remove_collect_batch",{ params: { app_ids: this.result } }).then(res => {
            const { code, data, msg } = res
            if (code === 0) {
              Toast.success(msg);
              this.getList()
            }
         }).catch(error => {
          console.log(error)
        })
      }).catch(() => {});
      } else {
        this.editStaus = false;
      }
    },
    onLoad() {
        // setTimeout(() => {
        //   for (let i = 0; i < 10; i++) {
        //     this.collectList.push({
        //       app_id: "app04iGOaEm6749",
        //       case_name: "赛凡国际空中课堂",
        //       city: "浙江省",
        //       cover_url: "http://wechatapppro-1252524126.file.myqcloud.com/app1guaHzN66620/image/cmVzb3VyY2Utc2hvcFNldHRpbmctNjQwMDkwMjM.jpg",
        //       h5: 1,
        //       h5_url: "http://h5.xiaoeknow.com/app04iGOaEm6749/homepage",
        //       industry_type: "管理营销",
        //       introduction: "案例简介案例简介案例简介案例简介案例简介案例简介案例简介案例简介",
        //       micro_program: 1,
        //     });
        //   }
				++this.page_index
				setTimeout(() => {
					this.getList()
          this.isLoading = false;
          if (this.collectList.length >= this.total) {
            this.finished = true;
          }
        }, 1000);
    },
    goDetails(app_id) {
        this.$router.push({ path: '/detail', query: { appid: app_id }})
    }
  },
};
</script>

<style lang="scss" scoped>
.collect {
	.collect-head {
		padding: 0 0.32rem 0 0.32rem;
		.collect-content-check {
			width: 0.4rem;
			margin-right: 0.16rem;
		}
		.collect-content {
			height: 2.32rem;
			width: 100%;
			display: flex;
			margin-top: 0.32rem;
			.collect-content-img {
				width: 2.67rem;
				height: 100%;
        overflow: hidden;
				// background: red;
			}
			.collect-content-text {
				height: 0.4rem;
				margin-left: 0.24rem;
        flex: 1;
				.collect-content-text-name {
					font-size: 0.32rem;
					font-weight: 500;
					color: rgba(51, 51, 51, 1);
          margin-bottom: .16rem;
				}
				.collect-content-text-detail {
					font-size: 0.24rem;
          color: #666666;
          margin-bottom: .16rem;
          overflow: hidden;
          text-overflow: ellipsis;
          height: .64rem;
          // width: 4.3rem;
          display: -webkit-box;
         -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
				}
				.collect-content-text-range {
          margin-bottom: .16rem;
					.text-tag {
						font-size: 0.2rem;
						color: #000;
						position: relative;
						font-weight: 500;
						padding: 0.04rem 0.16rem;
						&::before {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							background: rgba(224, 223, 223, 0.65);
							width: 100%;
							height: 0.32rem;
							border-radius: 0.04rem;
						}
					}
          .marginL {
            margin-left: .16rem;
          }
				}
				.collect-content-text-address {
					.address-img {
						width: 0.24rem;
					}
          .address-province {
            font-size: .2rem;
            color: #999999;
            margin-left: .04rem;
          }
				}
			}
		}
	}
	.collect-footer {
		position: fixed;
		bottom: 0;
		background: #fff;
		width: 100%;
		.collect-footer-content {
			padding: 0.16rem 0.32rem;
			display: flex;
			justify-content: space-between;
			.collect-footer-btn {
				height: 0.8rem;
				width: 1.92rem;
			}
			.marginL {
				margin-left: 0.16rem;
			}
		}
		.collect-footer-edit {
			padding: 0.16rem 0.4rem;
			.collect-footer-btn {
				height: 0.8rem;
				width: 100%;
			}
		}
	}
}
</style>

